import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">
            Wealth Planning Services
          </h2>
        }
      />
      <ContentInner
        heading={'Bespoke Wealth Management'}
        content={
          <p>
            While many investors see the money management process as the buying
            and selling of securities, we believe the process begins by getting
            to know each individual personally, by listening to their goals and
            concerns. We create a path to achieving their goals, and while money
            management plays a big part in that process, so does tax planning,
            estate planning, retirement services, insurance, and education
            solutions-to name but a few.
            <br />
            <br />
            At the heart of proper management is the relationship we have with
            each individual, so they know that they can reach out to us whenever
            life throws a curve at one’s plans. This could be a job-related
            event, a healthcare issue, or one of life’s rites of passage, like a
            child’s wedding. We are here to make sure you stay on path.
            <br />
            <br />
            And while there are no cookie-cutter portfolios or platforms from
            us, there are certain beliefs that hold true for how we manage
            everyone’s assets. Where possible, we try to minimize taxes so that
            more money goes in your pocket and not the government’s. We often
            use low-cost ETFs to diversify and cast a wide net as a part of a
            portfolio; these allow for “tax-friendly” sales in our control as
            well as lower fees than mutual funds. Lastly, we will ensure that no
            one investment ever dominates a portfolio and puts you at risk from
            achieving your goals. Diversification is paramount to what we do.
            <br />
            <br />
            Lastly, money management does not necessarily end when you get to
            your long-term goal. The road to perfection is always under
            construction, and a new set of goals and challenges may likely
            emerge. That could include proper estate and tax planning, as well
            as legacy-defining gift giving. You can count on life constantly
            changing, and when it does, you can also rest assured that DGM will
            be there to help lead the way.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
